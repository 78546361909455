<template>
  <div class="language-test overflow-x-auto">
    <div class="border-primary rounded custom-border">
      <h6 class="text-primary">
        Results/Scores -
        {{ language_exam.label }}
      </h6>
      <CRow>
        <CCol md="6" v-if="!isEdit">
          <CRow class="d-flex align-items-center mb-3">
            <label name="test_taken" class="col-lg-12 col-md-12 required"
              >Type of test taken</label
            >
            <div class="col-lg-12 col-md-12 col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <Select
                  name="test_type"
                  :value="formData.test_type"
                  :options="(options && options['testTaken']) || []"
                  @input="handleChangeSelectCustom"
                  :error="errors[0]"
                  :clearable="false"
                />
              </ValidationProvider>
            </div>
          </CRow>
        </CCol>
        <CCol md="6" v-if="!isEdit">
          <CRow class="d-flex align-items-center mb-3">
            <label
              name="date_obtained_label"
              class="col-lg-12 col-md-12 required"
              >Date Obtained</label
            >
            <div class="col-lg-12 col-md-12 col-sm-12">
              <ValidationProvider
                name="date_obtained"
                rules="required|date_validate|no_future_date"
                v-slot="{ errors }"
              >
                <DateInput
                  :onlyMonthAndYear="false"
                  name="date_obtained"
                  :value="formData.date_obtained"
                  @change="handleDatePickerChange"
                  :pastYearLimit="20"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </CRow>
        </CCol>
      </CRow>
    </div>
    <div class="test-grid mb-3">
      <CRow class="mx-0">
        <CCol md="12" class="py-2">
          <CRow class="px-0 flex-nowrap">
            <CCol class="test-title">
              <label class="required">Category</label>
            </CCol>
            <CCol class="test-title" v-if="!isIELTS">
              <label class="required">Score</label>
            </CCol>
            <CCol class="test-title">
              <label :class="{ required: isIELTS }">Grade (CEFR)</label>
            </CCol>
            <CCol class="test-title">
              <label class="required">Category</label>
            </CCol>
            <CCol class="test-title" v-if="!isIELTS">
              <label class="required">Score</label>
            </CCol>
            <CCol class="test-title">
              <label class="{'required':isIELTS}">Grade (CEFR)</label>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="12">
          <CRow
            v-for="(field, index) in getResultFields"
            :key="index"
            class="mt-3 flex-nowrap"
          >
            <CCol
              v-for="(subField, subIndex) in field"
              :key="subIndex"
              class="pb-3 field"
            >
              <div v-if="subField.type === 'label'">{{ subField.value }}</div>
              <div v-else-if="subField.type === 'name'">
                <div v-if="isIELTS">
                  <ValidationProvider
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <Select
                      :name="`${subField.value}_score`"
                      :value="formData[`${subField.value}_score`]"
                      @change="
                        (name, value) =>
                          handleChangeSelect(name, value, subField)
                      "
                      :options="grades(subField)"
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div v-else>
                  <ValidationProvider
                    :rules="{
                      required: true,
                      numeric: true,
                      min_value: getMinScore(subField.value),
                      max_value: getMaxScore(subField.value),
                    }"
                    v-slot="{ errors }"
                  >
                    <TextInput
                      :name="`${subField.value}_score`"
                      :value="formData[`${subField.value}_score`]"
                      @change="
                        (name, value) => handleInput(name, value, subField)
                      "
                      :min="getMinScore(subField.value)"
                      :max="getMaxScore(subField.value)"
                      :error="errors[0]"
                      class="score-input"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div v-else>
                {{ formData[subField.value] | grade }}
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
    <CRow>
      <CCol md="6" v-if="!isOfficialExam">
        <CRow class="d-flex align-items-center mb-3">
          <label name="official_exam_label" class="col-lg-12 col-md-12 required"
            >Have you booked your Official Exam Test?</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <RadioButton
                name="assessment_test"
                :value="formData.assessment_test"
                :options="(options && options['boolean']) || []"
                @change="handleChangeRadio"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="6" v-if="formData.assessment_test">
        <CRow class="d-flex align-items-center mb-3">
          <label name="booked_date_label" class="col-lg-12 col-md-12 required"
            >Exam Booked Date</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider
              name="official_test_booked"
              rules="required|date_validate"
              v-slot="{ errors }"
            >
              <DateInput
                :onlyMonthAndYear="false"
                name="official_test_booked"
                :value="formData.official_test_booked"
                @change="handleDatePickerChange"
                :futureYearLimit="10"
                :futureYear="true"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from "lodash";
import { extend } from "vee-validate";
import {
  required,
  min_value,
  max_value,
  numeric,
} from "vee-validate/dist/rules";
extend("numeric", { ...numeric, message: "This field accept only numeric" });
extend("min_value", (value, args) => {
  const min = args?.min;
  if (min <= value) {
    return true;
  }
  return `Min Value is ${min}`;
});
extend("max_value", (value, args) => {
  const max = args?.max;
  if (max >= value) {
    return true;
  }
  return `Max Value is ${max}`;
});
extend("required", { ...required, message: "This field is required" });
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import m from "moment";

import TextInput from "@/components/reusable/Fields/TextInput";
import DateInput from "@/components/reusable/Fields/DateInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Select from "@/components/reusable/Fields/Select";
export default {
  name: "LanguageTestSec",
  props: {
    sections: {
      type: Array,
      default: [],
    },
    language_exam: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    testInfo: {
      required: false,
      type: Array,
      default: () => [],
    },
    isAddTest: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    sectionIndex: {
      type: Number,
      default: null,
    },
  },
  components: {
    TextInput,
    DateInput,
    Select,
    RadioButton,
  },
  data() {
    return {
      formData: {},
      payload: [],
    };
  },
  computed: {
    ...mapGetters([
      "getLanguageExamScores",
      "getTestExamTypes",
      "getCandidateTestScores",
    ]),
    options() {
      return {
        testTaken: this.customExamTypes,
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
      };
    },
    isOfficialExam() {
      return this.formData?.test_type
        ? this.formData?.test_type?.code === 1
        : true;
    },
    customExamTypes() {
      const examCode = this.language_exam?.code;
      return this.getTestExamTypes.filter((v) =>
        v?.code === 4
          ? false
          : true
      );
    },
    getResultFields() {
      const list = this.sections;
      let matrix = [],
        i,
        k;
      for (i = 0, k = -1; i < list.length; i++) {
        if (i % 2 === 0) {
          k++;
          matrix[k] = [];
        }
        const data = list[i];
        const label = { value: data.label, type: "label" };
        const name = {
          value: data.name,
          type: "name",
          section_id: data.section_id,
          test_id: data.test_id,
        };
        let res = [label, name];
        if (!this.isIELTS) {
          const grade = { value: `${data.name}_grade`, type: "grade" };
          res = [...res, grade];
        }
        matrix[k] = [...matrix[k], ...res];
      }
      return matrix;
    },
    isIELTS() {
      return this.language_exam?.code === 2 || false;
    },
  },
  methods: {
    ...mapActions(["fetchLanguageExamScores", "fetchTestExamTypes"]),
    grades(data) {
      const res = this.getLanguageExamScores
        .filter(
          (v) =>
            v.test_section_id === data?.section_id || v.test_section_id === null
        )
        .map((v) => ({
          code: v?.start_score,
          label: v?.grade,
        }));
      return _.uniqBy(res, "code");
    },
    handleChangeSelectCustom(name, value) {
      this.formData = {
        ...this.formData,
        assessment_test: null,
        official_test_booked: null,
      };
      Vue.set(this.formData, name, value);
      this.$emit("testTypeChange", value);
      if (name === "test_type" && value?.code != 1) {
        this.setUpAssement();
      }
    },
    setUpAssement() {
      const examCode = this.language_exam?.code;
      const officialExamTest = this.getCandidateTestScores.find(
        (v) =>
          v.test_id === examCode &&
          v.test_type === "Official Exam Test Result" &&
          v.date_obtained
      );
      if (officialExamTest) {
        this.handleChangeRadio("assessment_test", true);
        this.handleDatePickerChange(
          "official_test_booked",
          officialExamTest?.date_obtained
        );
      }
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null || value === undefined) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.formData, name, val);
    },
    handleChangeSelect(name, value, subField) {
      Vue.set(this.formData, name, value);

      const split_index = name.lastIndexOf("_");
      const _name = name.substr(0, split_index);
      const field = `${_name}_grade`;
      const { section_id, test_id } = subField;
      const index = this.payload.findIndex(
        (v) => v.test_section_id == section_id
      );
      const score = parseFloat(value?.code);
      const grade = this.getGrade(score, section_id);
      Vue.set(this.formData, field, grade);
      const data = { score, test_score_id: grade?.test_score_id };
      if (index >= 0) {
        this.payload[index] = {
          ...this.payload[index],
          ...data,
        };
      } else {
        this.payload.push({ test_section_id: section_id, ...data });
      }
    },
    getMinScore(name) {
      if (this.language_exam) {
        const section_id = this.getSectionId(name);
        Array.prototype.hasMin = function (attrib) {
          return (
            (this.length &&
              this.reduce(function (prev, curr) {
                return prev[attrib] < curr[attrib] ? prev : curr;
              })) ||
            null
          );
        };

        const minObj = this.getLanguageExamScores
          .filter(
            (v) =>
              v.test_section_id === section_id || v.test_section_id === null
          )
          .hasMin("start_score");
        return minObj?.start_score;
      }
    },
    getMaxScore(name) {
      if (this.language_exam) {
        const section_id = this.getSectionId(name);

        Array.prototype.hasMax = function (attrib) {
          return (
            (this.length &&
              this.reduce(function (prev, curr) {
                return prev[attrib] > curr[attrib] ? prev : curr;
              })) ||
            null
          );
        };
        const maxObj = this.getLanguageExamScores
          .filter(
            (v) => v.test_section_id == section_id || v.test_section_id === null
          )
          .hasMax("end_score");
        return maxObj?.end_score || 100;
      }
    },
    getSectionId(name) {
      const test_id = this.language_exam.code;
      const section_id = test_id
        ? this.sections.find((v) => v.test_id == test_id && v.name == name)
            ?.section_id
        : null;
      return section_id;
    },
    handleInput(name, value, subField) {
      Vue.set(this.formData, name, value);
      const split_index = name.lastIndexOf("_");
      const _name = name.substr(0, split_index);
      const field = `${_name}_grade`;
      const { section_id, test_id } = subField;
      const index = this.payload.findIndex(
        (v) => v.test_section_id == section_id
      );
      const score = parseFloat(value);
      const grade = this.getGrade(score, section_id);
      Vue.set(this.formData, field, grade);
      const data = { score: value, test_score_id: grade?.test_score_id };
      if (index >= 0) {
        this.payload[index] = {
          ...this.payload[index],
          ...data,
        };
      } else {
        this.payload.push({ test_section_id: section_id, ...data });
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(
        this.formData,
        name,
        value ? m(value).format("YYYY-MM-DD") : null
      );
    },
    getGrade(score, section_id) {
      let val = this.getLanguageExamScores.find(
        (v) =>
          (v.test_section_id == section_id || v.test_section_id === null) &&
          (v.end_score ? v.end_score >= score : v.start_score == score) &&
          v.start_score <= score
      );
      return val || null;
    },
    prePopulateData(data) {
      data.forEach((element) => {
        const section_name = _.snakeCase(element.test_section.section_name);
        const scoreName = `${section_name}_score`;
        const gradeName = `${section_name}_grade`;
        const {
          score,
          test_section_id,
          test_score_id,
          candidate_test_id,
          test_id,
          test_score,
        } = element;
        const grade = this.getGrade(score, test_section_id);
        let scoreData = score;
        if (test_id === 2) {
          scoreData = {
            code: score,
            label: test_score?.grade,
          };
        }
        Vue.set(this.formData, scoreName, scoreData);
        Vue.set(this.formData, gradeName, grade);
        const payload = {
          test_section_id,
          test_score_id,
          score,
          candidate_test_id,
        };
        this.payload.push(payload);
      });
    },
    getPayload() {
      const payload = {
        test_type: this.formData.test_type?.label,
        official_test_booked: this.formData?.official_test_booked,
        assessment_test: this.formData?.assessment_test,
        date_obtained: this.formData?.date_obtained,
        section_data: this.payload,
      };
      return payload;
    },
  },
  mounted() {
    let appendAction = [];
    if (!this.getTestExamTypes.length)
      appendAction.push(this.fetchTestExamTypes());
    if (this.language_exam?.code)
      appendAction.push(this.fetchLanguageExamScores(this.language_exam?.code));

    Promise.all(appendAction).then((res) => {
      if (this.isEdit && this.testInfo?.length) {
        this.prePopulateData(this.testInfo);
      }
    });
  },
  filters: {
    grade(data) {
      return data?.grade ? `${data?.grade} (${data?.cefr_grade})` : "";
    },
  },
  watch: {
    language_exam(newVal, oldVal) {
      this.formData = {};
      this.payload = [];
      const test_id = newVal?.code;
      if (test_id) this.fetchLanguageExamScores(this.language_exam?.code);
    },
    "formData.test_type"(newVal) {
      if (newVal.code === 2 && this.sectionIndex !== null) {
        this.handleChangeRadio("assessment_test", true);
        this.handleDatePickerChange(
          "official_test_booked",
          this.getCandidateTestScores[this.sectionIndex].official_test_booked
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.test-title {
  vertical-align: bottom;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  padding: 0.75rem;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
  font-weight: bold;
}
.field {
  vertical-align: top;
  border-bottom: 1px solid;
  border-bottom-color: #d8dbe0;
}
.test-grid .col {
  min-width: 120px;
}

@media (max-width: 767px) {
  .overflow-x-auto {
    overflow-x: auto;
  }
}
.custom-border {
  margin: -5px;
  padding: 5px;
}
</style>
