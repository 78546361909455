<template>
  <div class="circle-progressbar">
    <CChartPie
      :datasets="defaultDatasets"
      :labels="labels"
      :options="options"
      style="height: auto"
      :style="`width:${width}`"
      :plugins="plugins(this.progressValue)"
    />
  </div>
</template>
<script>
import { CChartPie } from "@coreui/vue-chartjs";

export default {
  name: "CChartDoughnutExample",
  components: { CChartPie },
  props: {
    progressValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "Completion",
    },
    color: {
      type: String,
      default: "primary",
    },
    width: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        cutoutPercentage: 80,
        tooltips: {
          enabled: false,
        },
        hover: { mode: null },
      },
    };
  },
  computed: {
    defaultDatasets() {
      const style = getComputedStyle(document.body);
      const bg_color = style.getPropertyValue("--primary");
      const colors = [bg_color, "#AAAAAA"];
      return [
        {
          backgroundColor: colors,
          hoverBackgroundColor: colors,
          hoverBorderColor: colors,
          data: [this.progressValue, 100 - this.progressValue],
        },
      ];
    },
    labels() {
      return [this.label, ""];
    },
  },
  methods: {
    plugins(value) {
      return [
        {
          beforeDraw: function (chart) {
            var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx;

            ctx.restore();
            var fontSize = (height / 50).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            var text = value + "%",
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.donut {
  width: auto;
  position: relative;
  .donut-inner {
    width: 100%;
    top: 7em;
    position: relative;
    left: 0;
    margin-top: -20px;
    line-height: 19px;
    text-align: center;
    z-index: 9;
    b {
      font-size: 25px;
    }
  }
}
</style>
